import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category") || "";
  const brandData = searchParams.get("brand") || "";
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (allProducts) {
      let filteredProducts = allProducts;

      if (categoryData) {
        filteredProducts = filteredProducts.filter(
          (i) =>
            i.category &&
            i.category.toUpperCase() === categoryData.toUpperCase()
        );
      }

      if (brandData) {
        filteredProducts = filteredProducts.filter(
          (i) => i.brand && i.brand.toUpperCase() === brandData.toUpperCase()
        );
      }

      setData(filteredProducts);
    }
  }, [allProducts, categoryData, brandData]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={3} />
          <br />
          <br />
          <div className={`${styles.section}`}>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-[30px] lg:grid-cols-4 lg:gap-[30px] xl:grid-cols-5 xl:gap-[40px] mb-12 justify-center mx-auto px-1">
              {data.length > 0 ? (
                data.map((i, index) => <ProductCard data={i} key={index} />)
              ) : (
                <h1 className="text-center w-full pb-[100px] text-[20px]">
                  No products Found!
                </h1>
              )}
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;
