import React from "react";
import { useNavigate, Link } from "react-router-dom";
import styles from "../../styles/styles";
import { IoIosArrowDropdown } from "react-icons/io";
import { useState } from "react";

const DropDown = ({ categories, setDropDown }) => {
  const navigate = useNavigate();
  const [openCategoryIndex, setOpenCategoryIndex] = useState(null);

  const submitHandle = (i) => {
    navigate(`/products?category=${i.categoryName}`);
    setDropDown(false);
    /*     window.location.reload();
     */
  };

  const toggleBrands = (index) => {
    setOpenCategoryIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="pb-4 w-[200px] bg-[#fff] absolute z-30 rounded-b-md shadow-sm">
      {categories &&
        categories.map((i, index) => (
          <div key={index} className="relative">
            <div className={`${styles.noramlFlex} px-4`}>
              <h3
                className="m-3 cursor-pointer select-none text-center"
                onClick={() => submitHandle(i)}
              >
                {i.categoryName}
              </h3>
              <div onClick={() => toggleBrands(index)}>
                <IoIosArrowDropdown
                  style={{
                    transform:
                      openCategoryIndex === index
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    transition: "transform 0.3s",
                  }}
                />
              </div>
            </div>

            {openCategoryIndex === index && (
              <div className="ml-4 flex flex-col">
                {i.brands.map((brand, brandIndex) => (
                  <div key={brandIndex} className="ml-6 mr-3 text-base">
                    <Link
                      to={`/products?category=${i.categoryName}&brand=${brand}`}
                      onClick={() => setDropDown(false)}
                      className=" "
                    >
                      {brand}
                    </Link>
                    <hr />
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default DropDown;
