import React from "react";
import styles from "../../styles/styles";

const Sponsored = () => {
  return (
    <div
      className={`${styles.section} hidden sm:block bg-white py-10 px-5 mb-12 cursor-pointer rounded-xl`}
    >
      <div className="flex justify-between flex-wrap w-full">
        {/* Sony */}
        <div className="flex items-start mb-4 sm:mb-0">
          <img
            src="https://logos-world.net/wp-content/uploads/2020/04/Sony-Logo.png"
            alt="Sony"
            style={{ width: "120px", maxHeight: "40px", objectFit: "contain" }}
          />
        </div>
        {/* Dell */}
        <div className="flex items-start mb-4 sm:mb-0">
          <img
            src="https://logos-world.net/wp-content/uploads/2020/08/Dell-Logo-1989-2016.png"
            alt="Dell"
            style={{ width: "120px", maxHeight: "40px", objectFit: "contain" }}
          />
        </div>
        {/* LG */}
        <div className="flex items-start mb-4 sm:mb-0">
          <img
            src="https://cdn.shopify.com/s/files/1/0558/6413/1764/files/LG_Logo_Design_History_Evolution_0_1024x1024.jpg?v=1692865759"
            alt="LG"
            style={{ width: "120px", maxHeight: "40px", objectFit: "contain" }}
          />
        </div>
        {/* mac */}
        <div className="flex items-start mb-4 sm:mb-0">
          <img
            src="https://media.licdn.com/dms/image/D4D12AQHwi4jdRd3fQQ/article-cover_image-shrink_600_2000/0/1685279753620?e=2147483647&v=beta&t=7I-pJ0kDQfNl4w-0Ue8aPyol_X-aWOQlzp18NhTldys"
            alt="HP"
            style={{ width: "120px", maxHeight: "40px", objectFit: "contain" }}
          />
        </div>
        {/* HP */}
        <div className="flex items-start mb-4 sm:mb-0">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/HP_logo_2012.svg/800px-HP_logo_2012.svg.png"
            alt="HP"
            style={{ width: "120px", maxHeight: "40px", objectFit: "contain" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Sponsored;
