import React, { useState, useEffect } from "react";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import { FaWhatsapp } from "react-icons/fa";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import { server2 } from "../../../server";
import { openWhatsApp } from "../../../static/data";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist, data._id]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <div className="w-[250px] h-[300px] mx-auto bg-white rounded-lg shadow-md p-3 relative overflow-hidden">
      <Link
        to={`${
          isEvent === true
            ? `/product/${data._id}?isEvent=true`
            : `/product/${data._id}`
        }`}
      >
        <img
          src={`${server2}${data?.images && data?.images[0].url}`}
          alt={data.name}
          className="w-full h-[150px] object-cover"
        />
        <h4 className="text-sm font-medium mt-2 mb-1 px-1 overflow-hidden text-ellipsis whitespace-nowrap">
          {data?.name?.length > 60
            ? data?.name.slice(0, 60) + "..."
            : data?.name}
        </h4>

        <div className="flex items-center">
          <Ratings rating={data?.ratings} />
        </div>

        <div className="py-2 flex items-center justify-between">
          <div className="flex flex-row items-center justify-between">
            <h5 className={`${styles.productDiscountPrice} text-xs`}>
              {data?.discountPrice} Ksh
            </h5>
            {data?.originalPrice ? (
              <h4 className={`${styles.price} text-xs line-through`}>
                {data?.originalPrice} Ksh
              </h4>
            ) : null}
          </div>
        </div>
      </Link>

      {/* Side options */}
      <div>
        {click ? (
          <AiFillHeart
            size={22}
            className="cursor-pointer absolute right-2 top-5"
            onClick={() => removeFromWishlistHandler(data)}
            color={click ? "red" : "#FF0000"}
            title="Remove from wishlist"
          />
        ) : (
          <AiOutlineHeart
            size={22}
            className="cursor-pointer absolute right-2 top-5"
            onClick={() => addToWishlistHandler(data)}
            color={click ? "red" : "#FF0000"}
            title="Add to wishlist"
          />
        )}
        <AiOutlineEye
          size={22}
          className="cursor-pointer absolute right-2 top-14"
          onClick={() => setOpen(!open)}
          color="#FF0000"
          title="Quick view"
        />
        <AiOutlineShoppingCart
          size={25}
          className="cursor-pointer absolute right-2 top-24"
          onClick={() => addToCartHandler(data._id)}
          color="#FF0000"
          title="Add to cart"
        />
      </div>

      {/* WhatsApp Button */}
      <button
        className="absolute bottom-3 right-3 bg-green-500 text-white px-2 py-1 rounded-md flex items-center space-x-1"
        onClick={() => openWhatsApp(data)}
      >
        <FaWhatsapp size={16} color="#fff" />
        <span className="text-xs">Buy on WhatsApp</span>
      </button>

      {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
    </div>
  );
};

export default ProductCard;
