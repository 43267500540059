import React, { useEffect, useState } from "react";
import axios from "axios";
import { server, server2 } from "../../server";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateProduct } from "../../redux/actions/product";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const EditProductComponent = ({ productId, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    success,
    error,
    allProducts = [],
  } = useSelector((state) => state.products);
  // Corrected prop
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [stock, setStock] = useState("");
  const [condition, setCondition] = useState("New");
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [uniqueBrands, setUniqueBrands] = useState([]);
  const [newCategoryInput, setNewCategoryInput] = useState("");
  const [newBrandInput, setNewBrandInput] = useState("");

  useEffect(() => {
    if (productId) {
      // Corrected prop
      // Fetch product details
      axios
        .get(`${server}/product/get-product/${productId}`)
        .then((res) => {
          const product = res.data;
          setProductDetails(product);
          setImages(product.products?.images);
          setName(product.products?.name || "");
          setDescription(product.products?.description || "");
          setCategory(product.products?.category || "");
          setBrand(product.products?.brand || "");
          setOriginalPrice(product.products.originalPrice || "");
          setDiscountPrice(product.products.discountPrice || "");
          setStock(product.products.stock || "");
          setCondition(product.products.condition || "New");
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching product details:", error);
          setFetchError("Error fetching product details");
          setLoading(false);
        });
    }
  }, [productId]); // Corrected prop
  useEffect(() => {
    if (allProducts && Array.isArray(allProducts)) {
      const categories = [
        ...new Set(allProducts.map((product) => product.category)),
      ];
      const brands = [...new Set(allProducts.map((product) => product.brand))];
      setUniqueCategories(categories);
      setUniqueBrands(brands);
    }
  }, [allProducts]);

  useEffect(() => {
    // Update unique categories and brands if product details change
    if (productDetails) {
      // Example logic to update unique categories and brands
      // Replace with actual logic if you have a list of products available
      // setUniqueCategories([...new Set(allProducts.map((product) => product.category))]);
      // setUniqueBrands([...new Set(allProducts.map((product) => product.brand))]);
    }
  }, [productDetails]);

  const handleUploadProduct = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const handleDeleteProductImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object
    const formData = new FormData();

    // Check if images is an array of URLs or File objects
    if (Array.isArray(images) && images.length > 0) {
      // Check if the first item has a `url` property
      if (images[0] && typeof images[0] === "object" && images[0].url) {
        // If images are URL objects
        formData.append(
          "imageurl",
          JSON.stringify(images.map((img) => img.url))
        );
      } else if (images[0] instanceof File) {
        // If images are File objects
        images.forEach((image) => formData.append("images", image));
      }
    }

    // Append other form fields to FormData
    formData.append("name", name);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("brand", brand);
    formData.append("originalPrice", originalPrice);
    formData.append("discountPrice", discountPrice);
    formData.append("stock", stock);
    formData.append("condition", condition);

    // Dispatch the updateProduct action with FormData
    dispatch(updateProduct(productId, formData))
      .then((response) => {
        toast.success("Product updated successfully");
        closeModal();
      })
      .catch((error) => {
        console.error("Error updating product:", error);
      });
  };

  const handleAddCategory = () => {
    if (newCategoryInput.trim() !== "") {
      const newCategory = newCategoryInput.trim();
      setCategory(newCategory);
      setNewCategoryInput("");
      setUniqueCategories([...uniqueCategories, newCategory]);
    } else {
      // Handle error
    }
  };

  const handleAddBrand = () => {
    if (newBrandInput.trim() !== "") {
      const newBrand = newBrandInput.trim();
      setBrand(newBrand);
      setNewBrandInput("");
      setUniqueBrands([...uniqueBrands, newBrand]);
    } else {
      // Handle error
    }
  };

  if (loading) return <div>Loading...</div>;
  if (fetchError) return <div>{fetchError}</div>;
  return (
    <div className="w-full bg-white shadow h-auto overflow-y-auto overflow-x-hidden">
      <form
        aria-required={true}
        onSubmit={handleSubmit}
        className="w-full max-w-2xl py-10 flex flex-col items-center justify-center m-auto"
      >
        <h5 className="pb-3 text-xl">Update Product</h5>
        <div className="w-full">
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="w-full px-3 py-2 border rounded-md"
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="w-full mt-3">
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            name="description"
            value={description}
            rows={6}
            className="w-full px-3 py-2 border rounded-md"
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="w-full mt-3">
          <label className="pb-2">
            Category <span className="text-red-500">*</span>
          </label>
          <div className="flex">
            <select
              name="category"
              value={category}
              className="w-full px-3 py-2 border rounded-md"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Choose a category</option>
              {uniqueCategories.map((cat, index) => (
                <option value={cat} key={index}>
                  {cat}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="newCategory"
              placeholder="New category"
              value={newCategoryInput}
              className="w-full px-3 py-2 border rounded-md"
              onChange={(e) => setNewCategoryInput(e.target.value)}
            />
            <button
              type="button"
              className="ml-2 px-3 py-2 bg-green-500 text-white rounded-md"
              onClick={handleAddCategory}
            >
              Add
            </button>
          </div>
        </div>
        <div className="w-full mt-3">
          <label className="pb-2">
            Brand <span className="text-red-500">*</span>
          </label>
          <div className="flex">
            <select
              name="brand"
              value={brand}
              className="w-full px-3 py-2 border rounded-md"
              onChange={(e) => setBrand(e.target.value)}
            >
              <option value="">Choose a brand</option>
              {uniqueBrands.map((br, index) => (
                <option value={br} key={index}>
                  {br}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="newBrand"
              placeholder="New brand"
              value={newBrandInput}
              className="w-full px-3 py-2 border rounded-md"
              onChange={(e) => setNewBrandInput(e.target.value)}
            />
            <button
              type="button"
              className="ml-2 px-3 py-2 bg-green-500 text-white rounded-md"
              onClick={handleAddBrand}
            >
              Add
            </button>
          </div>
        </div>
        <div className="w-full mt-3">
          <label className="pb-2">
            Original Price <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="originalPrice"
            value={originalPrice}
            className="w-full px-3 py-2 border rounded-md"
            onChange={(e) => setOriginalPrice(e.target.value)}
            required
          />
        </div>
        <div className="w-full mt-3">
          <label className="pb-2">Discount Price</label>
          <input
            type="number"
            name="discountPrice"
            value={discountPrice}
            className="w-full px-3 py-2 border rounded-md"
            onChange={(e) => setDiscountPrice(e.target.value)}
          />
        </div>
        <div className="w-full mt-3">
          <label className="pb-2">
            Stock <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="stock"
            value={stock}
            className="w-full px-3 py-2 border rounded-md"
            onChange={(e) => setStock(e.target.value)}
            required
          />
        </div>
        <div className="w-full mt-3">
          <label className="pb-2">
            Condition <span className="text-red-500">*</span>
          </label>
          <select
            name="condition"
            value={condition}
            className="w-full px-3 py-2 border rounded-md"
            onChange={(e) => setCondition(e.target.value)}
            required
          >
            <option>Choose Condition </option>
            <option value="New">New</option>
            <option value="Refurbished">Refurbished</option>
            <option value="Used">Used</option>
          </select>
        </div>
        <div className="w-full mt-3">
          <label className="pb-2">Product Images</label>
          <input
            type="file"
            multiple
            className="w-full"
            onChange={handleUploadProduct}
          />
          <div>
            {images && images.length > 0 && (
              <div className="flex flex-wrap">
                {images.map((image, index) => (
                  <div key={index} className="relative inline-block mr-2">
                    <img
                      src={
                        image.url
                          ? `${server2}${image.url}`
                          : URL.createObjectURL(image)
                      }
                      alt={`Preview ${index}`}
                      className="w-20 h-20 object-cover"
                    />
                    <button
                      type="button"
                      className="absolute top-0 right-0 text-red-500"
                      onClick={() => handleDeleteProductImage(index)}
                    >
                      <MdDelete />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="w-full mt-6 flex justify-end">
          <button
            type="button"
            className="mr-3 px-4 py-2 bg-gray-500 text-white rounded-md"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProductComponent;
