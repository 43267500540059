import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import { toast } from "react-toastify";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const CreateProduct = ({ edit }) => {
  const {
    success,
    error,
    allProducts = [],
  } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState(""); // State for brand name
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [stock, setStock] = useState("");
  const [condition, setCondition] = useState("New"); // State for product condition
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [uniqueBrands, setUniqueBrands] = useState([]); // State for unique brands
  const [newCategoryInput, setNewCategoryInput] = useState("");
  const [newBrandInput, setNewBrandInput] = useState(""); // State for new brand input

  useEffect(() => {
    if (error) {
      console.log(error);
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      navigate("/admin-products");
      window.location.reload();
    }
  }, [dispatch, error, success, navigate]);

  useEffect(() => {
    if (allProducts && Array.isArray(allProducts)) {
      const categories = [
        ...new Set(allProducts.map((product) => product.category)),
      ];
      const brands = [...new Set(allProducts.map((product) => product.brand))];
      setUniqueCategories(categories);
      setUniqueBrands(brands);
    }
  }, [allProducts]);

  const handleUploadProduct = (e) => {
    const files = Array.from(e.target.files); // Get all selected files
    setImages(files);
  };

  const handleDeleteProductImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    images.forEach((image) => formData.append("images", image));
    formData.append("name", name);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("brand", brand); // Append brand to form data
    formData.append("originalPrice", originalPrice);
    formData.append("discountPrice", discountPrice);
    formData.append("stock", stock);
    formData.append("condition", condition); // Append condition to form data

    dispatch(createProduct(formData));
  };

  const handleAddCategory = () => {
    if (newCategoryInput.trim() !== "") {
      const newCategoryObj = {
        id: newCategoryInput.toLowerCase(),
        value: newCategoryInput,
        label: newCategoryInput,
      };
      setCategory(newCategoryInput);
      setNewCategoryInput("");
      setUniqueCategories([...uniqueCategories, newCategoryInput]);
    } else {
      toast.error("Please enter a valid category name.");
    }
  };

  const handleAddBrand = () => {
    if (newBrandInput.trim() !== "") {
      const newBrand = newBrandInput.trim();
      setBrand(newBrand);
      setNewBrandInput("");
      setUniqueBrands([...uniqueBrands, newBrand]);
    } else {
      toast.error("Please enter a valid brand name.");
    }
  };

  return (
    <div className="w-[90%] 800px:w-[50%] bg-white shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Create Product</h5>
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your product name..."
            required
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your product description..."
            required
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
            Category <span className="text-red-500">*</span>
          </label>
          <div className="flex items-center">
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px] mr-2"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Choose a category</option>
              {uniqueCategories.length > 0 ? (
                uniqueCategories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))
              ) : (
                <option value="">No categories available</option>
              )}
            </select>
          </div>
          <div className="flex gap-2 mt-4">
            <input
              type="text"
              value={newCategoryInput}
              onChange={(e) => setNewCategoryInput(e.target.value)}
              placeholder="New category"
              className="mt-2 appearance-none block px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
            <button
              type="button"
              className="bg-blue-500 text-white py-1 px-2 rounded-md ml-2"
              onClick={handleAddCategory}
            >
              Add Category
            </button>
          </div>
        </div>

        <br />
        <div>
          <label className="pb-2">
            Brand Name <span className="text-red-500">*</span>
          </label>
          <div className="flex items-center">
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px] mr-2"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              required
            >
              <option value="">Choose a brand</option>
              {uniqueBrands.length > 0 ? (
                uniqueBrands.map((br) => (
                  <option key={br} value={br}>
                    {br}
                  </option>
                ))
              ) : (
                <option value="">No brands available</option>
              )}
            </select>
          </div>
          <div className="flex gap-2 mt-4">
            <input
              type="text"
              value={newBrandInput}
              onChange={(e) => setNewBrandInput(e.target.value)}
              placeholder="New brand"
              className="mt-2 appearance-none block px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
            <button
              type="button"
              className="bg-blue-500 text-white py-1 px-2 rounded-md ml-2"
              onClick={handleAddBrand}
            >
              Add Brand
            </button>
          </div>
        </div>

        <br />
        <div>
          <label className="pb-2">
            Condition <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
            required
          >
            <option value="New">New</option>
            <option value="Refurbished">Refurbished</option>
            <option value="Used">Used</option>
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2">Original Price</label>
          <input
            type="number"
            name="price"
            value={originalPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder="Enter your product price..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="discountPrice"
            value={discountPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDiscountPrice(e.target.value)}
            placeholder="Enter your product price with discount..."
            required
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Product Stock <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="stock"
            value={stock}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setStock(e.target.value)}
            placeholder="Enter product stock..."
            required
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Upload Images <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            multiple
            onChange={handleUploadProduct}
            className="mt-2 border h-[35px] rounded-[5px]"
            required
          />
          <div className="mt-2">
            {images.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {images.map((image, index) => (
                  <div key={index} className="relative w-[100px] h-[100px]">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Product"
                      className="object-cover w-full h-full rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => handleDeleteProductImage(index)}
                      className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full"
                    >
                      <MdDelete />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <br />
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Create Product
        </button>
      </form>
    </div>
  );
};

export default CreateProduct;
