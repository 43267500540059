import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const Hero = () => {
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-screen bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage: "url(./assets/heroimage.jpg)",
        backgroundSize: "cover",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <h1
          className={`text-lg leading-[1.2] 800px:text-xl text-[#3d3a3a] font-bolder capitalize`}
        >
          Best Collection for <br /> Electronics and Computing Devices
        </h1>
        <p className="pt-5 tracking-widest text-[10px] font-[Poppins] font-bold text-[#2c2c2cba]">
          Explore our curated selection of high-quality electronics and
          computing devices. Whether you're looking for the latest smartphones,
          powerful laptops, cutting-edge cameras, or state-of-the-art gaming
          consoles, we have something for everyone.
        </p>
        <Link to="/products" className="inline-block">
          <div className={`${styles.button} mt-5`}>
            <span className="text-[#fff] font-[Poppins] text-[13px]">
              Shop Now
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
